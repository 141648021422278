<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col sm="6" md="8">
        <div>
          <h1>DRIVE TO EVALUATION</h1>
        </div>
      </v-col>
      <v-col md="2" sm="6">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EvaluationDetail',
                params: { id: $route.params.id },
              })
            "
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <customer-info />

    <v-row>
      <v-col cols="10" class="mb-p">
        <p>
          Drive Time from Current Location: {{ evaluation_info.drive_time }}
        </p>
        <p>Projected Arrival Time: {{ evaluation_info.arrival_time }}</p>
      </v-col>
    </v-row>
    <v-row class="space-between">
      <v-col md="2" sm="6">
        <div>
          <v-btn color="primary" dark block>Click to Drive</v-btn>
        </div>
      </v-col>
      <v-col md="2" sm="6">
        <div>
          <v-btn color="primary" dark block>Refresh</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <google-map />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";
import googleMap from "../../components/Map/Map";

export default {
  components: { CustomerInfo, googleMap },
  name: "EvaluationReschedule.vue",
  data() {
    return {
      evaluation_info: {
        username: "JOE CUSTOMER",
        eval_date: "9/1/2020",
        eval_time: "10:00",
        eval_daysout: "Days out from today",
        address: "565 Murlen",
        city: "Overland Park, KS",
        phone: "913-242-1234",
        email: "Jcust@gmail.com",
        drive_time: "12 minutes",
        arrival_time: "09:46:00 AM",
      },
    };
  },
};
</script>

<style scoped></style>
