<template>
  <v-row>
    <v-col cols="12" class="mb-p">
      <h2 class="mb-3">{{ evaluation_info.username }}</h2>
      <p><b>Date:</b> {{ evaluation_info.eval_date }}</p>
      <p><b>Time:</b> {{ evaluation_info.eval_time }}</p>
      <p><b>Address:</b> {{ evaluation_info.address }}</p>
      <p><b>City / ST:</b> {{ evaluation_info.city }}</p>
      <p>
        <b>Phone:</b
        ><a :href="'tel:' + evaluation_info.phone">
          {{ evaluation_info.phone }}</a
        >
      </p>
      <p>
        <b>Email:</b
        ><a :href="'email:' + evaluation_info.email">
          {{ evaluation_info.email }}</a
        >
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      evaluation_info: {
        username: "JOE CUSTOMER",
        eval_date: "9/1/2020",
        eval_time: "10:00",
        address: "565 Murlen",
        city: "Overland Park, KS",
        phone: "913-242-1234",
        email: "Jcust@gmail.com",
      },
    };
  },
};
</script>

<style scoped>
.mb-p p {
  margin: 6px 0px;
}
</style>
